import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

const postImgDir = "https://festpodroze.pl/img/posts/"

const Wrapper = styled.div`
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const ImgPost = styled.img`
    display: inline-block;
    width: 100%;
    transition: all 0.5s ease;
    position: relative;
    margin-bottom: 0;
`

const Span = styled.span`
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#fa0a64 0%, #8f0808 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;

    ::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #8f0808;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
    }
    ::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #8f0808;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
    }
`

const Card = styled(Link)`
    margin: 3rem 0.1rem 0 0.1rem;
    text-decoration: none;
    max-width: 90%;
    :hover ${ImgPost} {
        filter: brightness(1.2);
        transform: scale(1.01);
    }
    ${({ theme }) => theme.media.miniphone} {
        max-width: 92%;
    }
    ${({ theme }) => theme.media.phone} {
        max-width: 90%;
    }
    ${({ theme }) => theme.media.tablet} {
        max-width: 49%;
    }
    ${({ theme }) => theme.media.desktop} {
        max-width: 31%;
    }
`

const PhotoWrapper = styled.div`
    position: relative;
`

const H2 = styled.h2`
    color: blue;
    font-family: "loveloblack", sans-serif;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    flex-basis: 100%;
    padding: 0.5rem 0;
`

const BlockText = styled.p`
    flex-basis: 100%;
    margin-bottom: 0.3rem;
    font-size: 1rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.dark};
    height: 4.35rem;
    overflow: hidden;
`

const ContentPhoto = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
const Ribbon = styled.div`
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
`

const Price = styled.div`
    flex-basis: 100%;
    margin-left: auto;
    text-align: right;
    font-size: 1.9rem;
    margin-bottom: 0.6rem;
    font-weight: bold;
    font-style: italic;
    text-shadow: 2px 2px 4px ${({ theme }) => theme.colors.darkgrey};
    color: ${({ theme }) => theme.colors.white};
`
const Info = styled.div`
    flex-basis: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem 0.5rem 0 0.5rem;
`

const TimeTravel = styled.div`
    background: rgba(0, 175, 255, 0.9);
    flex-basis: 35%;
    text-align: right;
    padding: .1rem 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 1px 1px 2px ${({ theme }) => theme.colors.darkgrey};
    font-size: 0.8rem;
`

const Details = styled.div`
    background: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.darkblue};
    flex-basis: 100%;
    text-align: right;
    margin-left: auto;
    text-transform: uppercase;
    font-size: 0.7rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.LineColor};
    transition: all 0.5s ease;
    :hover {
        background: ${({ theme }) => theme.colors.darkblue};
        color: ${({ theme }) => theme.colors.white};
    }
`
const Booking = styled(Link)`
    text-decoration: none;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, -50%);
    background: ${({ theme }) => theme.colors.darkblueT};
    width: 90%;
    text-align: center;
    font-family: "loveloblack", sans-serif;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.white};
    padding: 0.2rem 0 0 0;
    transition: all 0.5s ease;
    :hover {
        background: ${({ theme }) => theme.colors.important};
    }
`

const Posts = props => {
    const emptyPhoto =
        "https://images.pexels.com/photos/1161547/pexels-photo-1161547.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=640&w=426"

    const allPosts = useStaticQuery(graphql`
        query {
            podroze {
                posts {
                    id
                    title
                    data
                    category
                    thumbnail
                    description
                    details {
                        html
                    }
                    slug
                    icon
                    active
                    price
                    departure
                    author {
                        name
                    }
                }
            }
        }
    `)

    const IsActiveInfo = props =>
        props.active ? (
            <Info>
                <Price>od {props.price}</Price>
                <TimeTravel>Termin: {props.departure}</TimeTravel>
            </Info>
        ) : null

    const ActiveRibbon = props =>
        props.active ? (
            <Ribbon>
                <Span>Aktywne</Span>
            </Ribbon>
        ) : null

    const ActiveBooking = props =>
        props.active ? (
            <Booking to={props.link}>Zapytaj o ofertę / Rezerwuj</Booking>
        ) : null

    const test = props => {
        if (props.category === "wolne-terminy") {
            return allPosts.podroze.posts.filter(post => post.active === true)
        } else {
            return allPosts.podroze.posts.filter(
                post => post.category === props.category
            )
        }
    }
    return (
        <Wrapper>
            {test(props).map(post => (
                <Card key={post.id} to={`/${post.slug}#nav`}>
                    <PhotoWrapper>
                        <ImgPost
                            alt={post.category}
                            src={
                                post.thumbnail
                                    ? postImgDir + post.thumbnail
                                    : emptyPhoto
                            }
                        />
                        <ContentPhoto>
                            <IsActiveInfo
                                active={post.active}
                                price={post.price}
                                departure={post.departure}
                            ></IsActiveInfo>

                            <ActiveRibbon active={post.active}></ActiveRibbon>
                            <ActiveBooking
                                active={post.active}
                                link={`/${post.slug}#contactForm`}
                            ></ActiveBooking>
                        </ContentPhoto>
                    </PhotoWrapper>
                    <H2>{post.title}</H2>
                    <BlockText>{post.description}</BlockText>
                    <Details>więcej...</Details>
                </Card>
            ))}
        </Wrapper>
    )
}

export default Posts
